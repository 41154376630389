<template>
  <div>
    <!-- 导航 -->
    <nav class="navbar navbar-expand-lg bg-body-tertiary fixed-top">
      <div class="container-fluid">
        <a class="navbar-brand" href="#"><img src="../assets/images/lighting-logo.png" alt=""></a>
      </div>
    </nav>


    <!-- 轮播图 -->
    <div id="carouselExampleAutoplaying" class="carousel slide" data-bs-ride="carousel">

      <div class="carousel-inner">
        <div class="carousel-item active" :style="{ backgroundImage: `url(${sliderImgUrl1})` }">
          <p class="up">{{ pageList[1].value }}</p>
          <p class="down">{{ pageList[2].value }}</p>
        </div>
        <div class="carousel-item" :style="{ backgroundImage: `url(${sliderImgUrl2})` }">
          <p class="up">{{ pageList[4].value }}</p>
          <p class="down">{{ pageList[5].value }}</p>
        </div>
        <div class="carousel-item" :style="{ backgroundImage: `url(${sliderImgUrl3})` }">
          <p class="up">{{ pageList[7].value }}</p>
          <p class="down">{{ pageList[8].value }}</p>
        </div>
      </div>

    </div>

    <!-- 播放窗口 -->
    <div class="viewport">
      <video :src="videoUrl" controls poster="../assets/images/没有数据.jpg" :autoplay="isPlay"></video>
    </div>

    <!-- 服务项目 -->
    <div class="project container">
      <div class="midTitle">
        <h2>{{ pageList[11].value }}</h2>
        <p>{{ pageList[12].value }}</p>
      </div>
      <div class="content">
        <div class="row">
          <div class="col-lg-3 col-md-6 btn1" @click="play1">
            <!-- <a href="#">1</a> -->
            <p class="myP1" :style="{ backgroundImage: `url(${blockImg1})` }">{{ pageList[13].value }}</p>
          </div>
          <div class="col-lg-3 col-md-6 btn2" @click="play2">
            <!-- <a href="#">2</a> -->
            <p class="myP2" :style="{ backgroundImage: `url(${blockImg2})` }">{{ pageList[14].value }}</p>
          </div>
          <div class="col-lg-3 col-md-6 btn3" @click="play3">
            <!-- <a href="#">3</a> -->
            <p class="myP3" :style="{ backgroundImage: `url(${blockImg3})` }">{{ pageList[15].value }}</p>
          </div>
          <div class="col-lg-3 col-md-6 btn4" @click="play4">
            <!-- <a href="#">4</a> -->
            <p class="myP4" :style="{ backgroundImage: `url(${blockImg4})` }">{{ pageList[16].value }}</p>
          </div>
        </div>
      </div>
    </div>

    <!-- 悬挂备案号 -->
    <p style="background:none;height: 5%; bottom: 0px;width: 100%;text-align: center; margin-top: 60px;">
      <img style="padding-top:1px; width: 20px;" src="../assets/images/police.fbd72247.png">
      <a class="banquan" style="color:#000;"
        href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=蜀ICP备2024109057号-1"
        target="_blank">蜀ICP备2024109057号-1</a>
    </p>

  </div>






</template>

<script src="https://cdn.jsdelivr.net/npm/axios/dist/axios.min.js"></script>
<script>

export default {

  data() {
    return {
      pageList: [
        "","","","","",
        "","","","","",
        "","","","","",
        "","","","","",
        "","","","","",
      ],
      
      sliderImgUrl1:  '',
      sliderImgUrl2:  '',
      sliderImgUrl3:  '',
      blockImg1: '',
      blockImg2: '',
      blockImg3: '',
      blockImg4: '',

      videoUrl: '',
      isPlay: false,



    }
  },
  methods: {
    play1() {
      this.videoUrl = this.pageList[21].value
      this.isPlay = true
      // document.querySelector('.viewport video').play()
    },
    play2() {
      this.videoUrl = this.pageList[22].value
      this.isPlay = true
      // document.querySelector('.viewport video').play()
    },
    play3() {
      this.videoUrl = this.pageList[23].value
      this.isPlay = true
      // document.querySelector('.viewport video').play()
    },
    play4() {
      this.videoUrl = this.pageList[24].value
      this.isPlay = true
      // document.querySelector('.viewport video').play()
    }
  },

  // ***** 钩子 *****
  async created() {
    console.log('发送初始化渲染请求');

    this.$axios({
     
      // url: "http://localhost:8081/api/selectPage",//请求【页面数据】接口
      url: "https://api.lighting.sc.cn:8848/api/indexSelectPage",//请求【页面数据】接口

    }).then(res => {
      this.pageList = res.data
      console.log(this.pageList) 


      //1.设置轮播图
      this.sliderImgUrl1 = this.pageList[0].value
      this.sliderImgUrl2 = this.pageList[3].value
      this.sliderImgUrl3 = this.pageList[6].value

      //2设置主视频及封面
      const mainVideo = document.querySelector('.viewport video')
      mainVideo.src = this.pageList[9].value
      mainVideo.poster = this.pageList[10].value

      //3.设置服务项目区域背景图片
      this.blockImg1 = this.pageList[17].value
      this.blockImg2 = this.pageList[18].value
      this.blockImg3 = this.pageList[19].value
      this.blockImg4 = this.pageList[20].value

      
      

    }).catch(error => {
      console.log(error);
    });

    


  },

}
</script>

<style lang="less" scoped>

// 头部导航
.bg-body-tertiary {
  background-color: transparent !important;
}

.navbar-brand {
  text-align: left;
  padding-left: 20px;
  img {
    width: 50%;
    
  }
}


// 轮播图: 小于768图片高度250 max；大于等于768图片高度400 min；大于等于992图片高度500
.carousel {

  // 响应式 → 媒体查询
  @media (max-width: 768px) {
    .carousel-item {
      height: 250px;
      font-weight: 100;
      color: #fff;
      text-align: center;
    }

    .up {
      margin-bottom: 10px;
      padding-top: 90px;
      line-height: 30px;
      font-size: 30px;
    }

    .down {
      margin: 0;
      line-height: 20px;
      font-size: 20px;
    }


  }

  @media (min-width: 768px) {
    .carousel-item {
      height: 400px;
      font-weight: 100;

      color: #fff;
      text-align: center;
    }

    .up {
      margin-bottom: 13px;
      padding-top: 140px;
      line-height: 50px;
      font-size: 50px;
    }

    .down {
      font-weight: 100;
      margin: 0;

      line-height: 30px;
      font-size: 30px;
    }

  }

  @media (min-width: 992px) {
    .carousel-item {
      height: 500px;
      font-weight: 100;
      text-align: center;
    }

    .up {
      margin-bottom: 16px;
      padding-top: 180px;
      line-height: 60px;
      font-size: 60px;
    }

    .down {
      margin: 0;
      line-height: 40px;
      font-size: 40px;
    }

  }





  .carousel-item {
    background-size: cover;
    background-position: center 0;
  }

  .carousel-item:nth-child(1) {
    background-image: url(../assets/images/没有数据.jpg);
  }

  .carousel-item:nth-child(2) {
    background-image: url(../assets/images/没有数据.jpg);
  }

  .carousel-item:nth-child(3) {
    background-image: url(../assets/images/没有数据.jpg);
  }

}

//主视频窗口
.viewport {
  margin-top: 60px;
  text-align: center;

  @media (max-width: 768px) {
    video {
      width: 375px;
    }
  }

  @media (min-width: 768px) {
    video {
      width: 720px;
    }
  }

  @media (min-width: 992px) {
    video {
      width: 1080px;
    }
  }

  @media (min-width: 1200px) {
    video {
      width: 1280px;
    }
  }

  @media (min-width: 1900px) {
    video {
      width: 1920px;
    }
  }
}

// 开源项目
// 视口宽度大于992；一行排4个盒子  col-lg-3
// 视口宽度大于768；一行排2个盒子  col-md-6
.project {
  margin-top: 60px;
  text-align: center;

  .row {
    div {
      margin-bottom: 10px;
      height: 200px;

      p {
        // 块级的宽度和父级一样大
        display: block;
        height: 200px;
        background-color: green;
        border-radius: 4px;

        line-height: 350px;
        font-size: 20px;
        color: #fff;
      }

      &:nth-child(1) p {
        background-color: #70c3ff;
        // background-image: url(../assets/images/bg-1.jpg);
        background-size: cover;
        cursor:pointer;
      }

      &:nth-child(2) p {
        background-color: #fd6a7f;
        // background-image: url(../assets/images/bg-2.jpg);
        background-size: cover;
        cursor:pointer;
      }

      &:nth-child(3) p {
        background-color: #7f8ea0;
        // background-image: url(../assets/images/bg-3.jpg);
        background-size: cover;
        cursor:pointer;
      }

      &:nth-child(4) p {
        background-color: #89d04f;
        // background-image: url(../assets/images/bg-4.jpg);
        background-size: cover;
        cursor:pointer;
      }
    }
  }
}
</style>